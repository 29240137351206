<template>
  <div>
    首页
  </div>
</template>

<script>
export default {
  name: "Home",
}
</script>

<style scoped>

</style>